@import url("https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@300&display=swap");

.containerload {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1 {
  font-size: 1.5rem;
}
.ring {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ring::before {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
  25% {
    transform: rotate(90deg);
    box-shadow: 0px 5px 5px #fffb21;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 0px 5px 5px #21c0ff;
  }
  75% {
    transform: rotate(270deg);
    box-shadow: 0px 5px 5px #bc21ff;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
}
